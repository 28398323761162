/**
 * 【表示用】Variantの「価格」から「商品1点あたりの費用」を計算
 */
;(($) => {

  const expr = '.js-variant-cost-calculate';

  const init = () => {
    const $settings = $('#product_hasOnlyDefaultVariant')
    const $is_manual_set_price_for_dropshipping = $('#product_is_manual_set_price_for_dropshipping')
    console.log("variant_cost_calculate...")

    console.log(111)
    // if ($settings.length) {
    if ($settings.data('commissionCalculationType') == "matching") {
      const checked = $is_manual_set_price_for_dropshipping.length && $is_manual_set_price_for_dropshipping.val() == 'true';
      // calculate cost for matching
      $('body').on('change keyup', expr, function (e) {
        // const checked = $is_manual_set_price_for_dropshipping.is(':checked');

        // calculate fields..
        const $element = $(e.currentTarget);
        const price = $element.val();
        const $costElm = $($element.data('for'));
        // shop_fee calculation
        const shop_fee_type = $element.data('shopFeeType');
        const shop_fee = shop_fee_type === "percent" ? Math.floor(price * $element.data('shopFeePercent') / 100) : $element.data('shopFeeYen');
        const shop_fee_percent = $element.data('shopFeePercent') / 100;
        const payment_fee = Math.floor(price * $element.data('paymentFeePercent') / 100);

        // tax is 10% fixed for matching..
        const tax_percent = 10;//is_8_percent_tax ? 8 : 10;
        // only for MS-Nap..
        const cost = Math.floor(price - (price * shop_fee_percent * (1 + tax_percent / 100)));

        $costElm.val(cost);
      });

      $(expr).trigger('change');
    } else if ($settings.data('commissionCalculationType') == "dropshipping") {
      const costSelector = '.js-variant-cost-calculate_cost'
      const isManualSet = $is_manual_set_price_for_dropshipping.length && $is_manual_set_price_for_dropshipping[0].type == 'checkbox' ? $is_manual_set_price_for_dropshipping.is(':checked') : $is_manual_set_price_for_dropshipping.val() == 'true';
      const is_8_percent_tax = $('#product_variants_is_reduced_tax').is(':checked') || $('#product_is_reduced_tax').is(':checked');

      $('body').on('click', "#product_variants_is_reduced_tax", function () {
        $(costSelector).trigger('change');
      });

      // calculate price for dropshipping
      $('body').on('change keyup', costSelector, function (elm) {
        // const checked = $is_manual_set_price_for_dropshipping.length && $is_manual_set_price_for_dropshipping[0].type == 'checkbox' ? $is_manual_set_price_for_dropshipping.is(':checked') : $is_manual_set_price_for_dropshipping.val() == 'true';
        // const is_8_percent_tax = $('#product_variants_is_reduced_tax').is(':checked') || $('#product_is_reduced_tax').is(':checked');

        if (!isManualSet) {
          // changed elm..
          const $cost = $(elm.currentTarget);
          const cost = Number($cost.val());
          const $price = $($cost.data('for'));
          console.log($cost.data('for'), $price[0]);
          // shop_fee calculation
          // set data on price field..
          const shop_fee_type = $settings.data('shopFeeType');
          const shop_fee = shop_fee_type === "percent" ? cost * $settings.data('shopFeePercent') / 100 : Number($settings.data('shopFeeYen'));
          // const shop_fee_decimal = $settings.data('shopFeePercent') / 100; // MS-Nap
          const payment_fee = Math.floor(cost * $settings.data('paymentFeePercent') / 100);

          const tax_percent = is_8_percent_tax ? 8 : 10;
          const tax = (cost + shop_fee) * (tax_percent / 100);
          const price = Math.floor((cost + shop_fee) * (1 + tax_percent / 100));
          console.log("if NaN exists, it's error..", cost, shop_fee, tax, price);

          // set calculated..
          $price.val(price);
        }
      });

      $(costSelector).trigger('change');
    }
    if ($(expr).length && !['percent', 'yen'].includes($(expr).first().data('shopFeeType'))) {
      alert("仕入れ値設定がされていません。管理者に問い合わせください。")
    }
  };
  $(init);

})(jQuery);
