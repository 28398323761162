import axios from 'axios';
window.showWarning = false
export default class ProductStock {
  changeBulkAmount(targetElement) {
    Swal.fire({
      showConfirmButton: false,
      html: `
            <div>
                <p>指定した商品の在庫数を一括変更します</p>
              <input type="number" placeholder="0" value="0" class="form-control" style="width: 100px" id="bulkAmount">
            </div>

            <div class="pt-3">
                <div class="text-left">
                  <div class="btn btn-outline-dark mr-2" onclick="Swal.close()">キャンセル</div>
                  <div class="btn btn-primary" onclick="productStock.setBulkAmount('${targetElement}')">反映する</div>
                </div>
            </div>
            <hr>
            <div class="btn btn-primary" onclick="productStock.setZeroAmount('${targetElement}')">在庫を 0にする</div>
      `
    })
  }

  sent(e) {
    let tmpShowWarning = window.showWarning
    if(confirm('在庫設定を保存します。よろしいですか?')) {
      window.showWarning = false
    } else {
      e.preventDefault();
      window.showWarning = tmpShowWarning
    }
  }

  changeDate(e) {
  if (!e.target.value) {
    e.preventDefault()
    alert("選択は必須です。")
    e.target.value = e.target.defaultValue
    return
  }
    $('#productStockSearch').submit()
  }

  setBulkAmount(targetElement) {
    $(targetElement).not(':disabled').val($("#bulkAmount").val())
    Swal.close()
    this.notifyChange()
  }

  setZeroAmount(targetElement) {
    Swal.fire({
      html: '選択した列の在庫を0にしますか?',
      showCancelButton: true,
      confirmButtonText: 'はい',
      cancelButtonText: 'いいえ'
    }).then((result) => {
      if (result.isConfirmed) {
        $(targetElement).val(0)
        Swal.close()
        this.notifyChange()
      }
    })
  }

  getDefault() {
    const year_month = $("#year_month").val()
    const productIds = $('.child-box:checked').map((_, element) => {
      return parseInt($(element).attr('name').match(/\d+/g))
    }).get()
    axios.get('/merchant/products/stock_weekly_settings/fetch', { params: {
      year_month: year_month, shopify_product_ids: productIds
    }}).then((response) => {
      console.log(response);
      response['data'].forEach((productData) => {
        productData[1].forEach((dateData) => {
          $(`[name="[product][${productData[0]}][days][${dateData[0]}][newQuantity]"]`).not(':disabled').val(dateData[1])
        })
      })
      productStock.notifyChange()
    })
  }

  notifyChange() {
    window.showWarning = false
    $('.productStock-product').each((_, productElement)=> {
      if(window.showWarning) return false
      if($(productElement).find('.child-box:checked').length === 0) return true

      $(productElement).find('.productStock-product-date').each((_, productDateElement) => {
        if(parseInt($(productDateElement).val()) !== parseInt($(productDateElement).data('origin-value'))) {
          window.showWarning = true
          return false
        }
      })
    })
    $('#productStock-notify').toggleClass('d-none', !showWarning)
    $('#productStock-save').prop('disabled', !showWarning)
  }

  onBeforeUnload() {
    $(window).on('beforeunload', function(event){
      if (showWarning) {
        const message = 'Are you sure you want to leave?';
        event.preventDefault();
        event.returnValue = message
        return message
      }
    });
  }

}
