/**
 * 「まとめてチェック」関連
 * parentsExpr      チェックボックス「まとめてチェック」のセレクタ
 * childrenExpr     アイテム個別チェックボックスのセレクタ
 * showCheckedExpr  1つ以上チェックされている時に表示させる要素のセレクタ
 *                  チェック0の場合は非表示
 * hideCheckedExpr  1つ以上チェックされている時に非表示させる要素のセレクタ
 *                  チェック0の場合は表示
 * ※ すべての要素は、ひとつのform要素内に入っている必要がある
 */
;(($) => {
  const parentsExpr = '.js-bulk-checkbox';
  const childrenExpr = '.js-bulk-checkbox-child';
  const showCheckedExpr = '.js-bulk-checkbox-show';
  const hideCheckedExpr = '.js-bulk-checkbox-hide';
  let $form, $parents, $children;
  let $showChecked, $hideChecked;
  let existsChecked = false;

  const init = () => {
    $parents = $(parentsExpr);
    if (!$parents.length) return;

    $form = $($parents[0]).parents('form');
    $children = $form.find(childrenExpr);
    if (!$children.length) return;

    $showChecked = $(showCheckedExpr);
    $hideChecked = $(hideCheckedExpr);

    $form.
      on('change', parentsExpr, onParentChanged).
      on('change', childrenExpr, onChildChanged);

    switchLinkedElements();
  };

  const onParentChanged = e => {
    const $element = $(e.currentTarget);
    const checked = $element.prop('checked');
    existsChecked = checked;
    $parents.each((key, element) => {
      $(element).prop('checked', checked);
    });
    $children.each((key, element) => {
      // skip if disabled
      if ($(element).prop('disabled')) {
        return;
      }
      $(element).prop('checked', checked).trigger('change');
    });
    switchLinkedElements();
    return false;
  };

  const onChildChanged = e => {
    // exclude disabled..
    const denominator = $children.length - $children.filter(':disabled').length;
    const numerator = $children.filter(':checked').length;
    existsChecked = true;
    if (numerator < denominator) {
      $parents.each((key, element) => {
        $(element).prop('checked', false);
      });
      if (numerator < 1) {
        existsChecked = false;
      }
    } else {
      $parents.each((key, element) => {
        $(element).prop('checked', true);
      });
    }
    switchLinkedElements();
    return false;
  };

  const switchLinkedElements = () => {
    if (existsChecked) {
      $showChecked.show();
      $hideChecked.hide();
      return;
    }
    $showChecked.hide();
    $hideChecked.show();
  };

  $(init);

})(jQuery);
